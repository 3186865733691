import React from "react"
import PropTypes from "prop-types"

const ASCENDING = 'ASC';
const DESCENDING = 'DESC';

const Table = (props) => {
  const config = {key: "Total", direction: DESCENDING}
  const [sortConfig, setSortConfig] = React.useState(config);
  const players = props.players
  const headers = props.headers

  const sortedItems = React.useMemo(() => {
    let items = [...players];
    if (sortConfig !== null) {
      items.sort((a,b) => {
        let a_val = a[sortConfig.key]
        let b_val = b[sortConfig.key]
        if (!isNaN(a_val)) {
          a_val = parseInt(a_val)
          b_val = parseInt(b_val)
        }
        if (a_val < b_val) {
          return sortConfig.direction === ASCENDING ? -1 : 1;
        }
        if (a_val > b_val) {
          return sortConfig.direction === ASCENDING ? 1 : -1;
        }
        return 0;
      });
    }
    return items;
  }, [players, sortConfig]);
  const requestSort = key => {
    let direction = ASCENDING;
    if (sortConfig && sortConfig.key === key && sortConfig.direction === ASCENDING) {
      direction = DESCENDING;
    }
    setSortConfig({key, direction});
  }
return (
    <table className="table table-sm table-bordered table-striped draft-table">
      <thead className="white-text ccs-navy-bg">
        <tr>
          {headers.map(header => (
            <th key={header} className="draft-col draft-header" onClick={() => requestSort(header)}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="ccs-navy-txt">
        {sortedItems.map(player => (
          <tr key={player.id} >
          {headers.map(header => (
            <td className={player['Gender'] === 'Male' ? 'male' : 'female'}>
              {player[header]}
            </td>
          ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

class ProductTable extends React.Component {
  render () {
    return (
      <Table
        headers={this.props.headers}
        players={this.props.players}
      >
      </Table>
    );
  }
}

ProductTable.propTypes = {
  greeting: PropTypes.string
};
export default ProductTable
